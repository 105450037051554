import type { ImageScaler, ImageScalerOpts } from "./sizing";
import { error } from "@crown/logging";

let scalers: ImageScaler[] = [];

export function registerImageScaler(scaler: ImageScaler) {
  if (!scalers.includes(scaler)) {
    scalers.push(scaler);
  }
}

export function scaleImage(
  opts: ImageScalerOpts,
  preferredScaler?: ImageScaler
): string {
  for (const scaler of preferredScaler
    ? [preferredScaler, ...scalers]
    : scalers) {
    const scaled = scaler(opts);

    if (scaled !== opts.src) {
      return scaled;
    }
  }

  const message = `Found no image scaler for ${opts.src}`;

  error(message);

  if (process.env.NODE_ENV !== "production") {
    return placeholderImageScaler(opts);
  }

  return opts.src;
}

/* Useful for seeing what resolutions are actually used */
export const placeholderImageScaler: ImageScaler = ({
  width,
  height,
  format,
}) =>
  `https://via.placeholder.com/${width}` +
  (height ? "x" + height : ``) +
  (format == "lowres" ? "/ffdea3/909090" : "/ffbf4d/000000");

export const storyblokImageScaler: ImageScaler = ({
  src,
  width,
  height,
  format,
  rawOpts,
}) => {
  if (src?.includes("storyblok.com")) {
    return `${src.replace(/ /g, "%20")}/m/${width}x${height ?? 0}/${
      format == "lowres"
        ? "filters:blur(40):quality(10)"
        : "filters:quality(50)"
    }${rawOpts ? "&" + rawOpts : ""}`;
  } else {
    return src;
  }
};

export const imgIxImageScaler: ImageScaler = ({
  src,
  width,
  height,
  format,
  rawOpts,
}) => {
  if (src?.includes("imgix.net/")) {
    return `${src.replace(/ /g, "%20")}?auto=format${
      format == "lowres" ? `%2Ccompress` : ``
    }&fit=crop&w=${width}${height ? `&h=${height}` : ""}&${
      format == "lowres" ? `q=5` : `q=50`
    }${rawOpts ? "&" + rawOpts : ""}`;
  } else {
    return src;
  }
};
