type Action = "POP" | "PUSH" | "REPLACE";
interface HistoryEvent {
  action: Action;
  url: URL;
  /** Whether to stay at the scroll position you are at
   * (rather than going to the top of the new page) */
  noScroll: boolean;
}

type HistoryListener = (event: HistoryEvent) => void;

function getURL() {
  return new URL(window.location.href);
}

function createHistory() {
  let listeners: HistoryListener[] = [];
  function dispatch(event: HistoryEvent) {
    listeners.forEach((cb) => cb(event));
  }

  let url = getURL();

  window.addEventListener("popstate", function handlePop() {
    url = getURL();
    dispatch({
      action: "POP",
      url,
      noScroll: false,
    });
  });

  return {
    url,
    listen(cb: HistoryListener) {
      listeners.push(cb);
      return () =>
        (listeners = listeners.filter((listener) => listener !== cb));
    },
    push(target: URL | string, state?: any, noScroll?: boolean) {
      window.history.pushState(
        state,
        "",
        typeof target === "string" ? target : target.toString()
      );
      url = getURL();
      dispatch({
        action: "PUSH",
        url,
        noScroll: noScroll || false,
      });
    },
    replace(target: URL | string, state?: any, shouldDispatch: boolean = true) {
      window.history.replaceState(
        state,
        "",
        typeof target === "string" ? target : target.toString()
      );
      url = getURL();
      shouldDispatch &&
        dispatch({
          action: "REPLACE",
          url,
          noScroll: false,
        });
    },
  };
}

function createFakeHistory(): ReturnType<typeof createHistory> {
  let url = new URL("http://localhost/");

  return {
    url,
    listen(cb: HistoryListener) {
      return () => [cb];
    },
    push() {},
    replace() {},
  };
}

export const history =
  typeof window != "undefined" ? createHistory() : createFakeHistory();
