// backward compatibility: they used to be here
export { GroupedError, UserMessageError } from "@crown/logging";

/** These interfaces serve to define the result of an API call.
 * The utilities in [server/response](server/response.ts) can be used to send responses from endpoints.
 * The utilities in [client/response](client/response.ts) can be used to parse fetch responses into these
 * interfaces
 */

export type SwrResponse<Data> = ErrorResponse | SuccessResponse<Data>;

/**
 * Information about an error that has occurred. This interface is sent from server to client as part of an error response.
 * It may be implemented by Error instances so that it can be thrown and caught.
 */
export interface ErrorResponseMessage {
  /** An internal error message. */
  message: string;
  /** A message that should be shown to the user. */
  userMessage?: string;
  /** A code for the error type. Used if the client needs to check if a certain type of error occurred */
  code?: string;
  /** An HTTP status code corresponding to the error. */
  status?: number;
  /** A unique ID for the error. */
  id?: string;
}

export interface SuccessResponse<Data> {
  result: "ok";
  data: Data;
}

export interface ErrorResponse {
  result: "error";
  error: ErrorResponseMessage;
}

export function successResponse<Data>(data: Data): SuccessResponse<Data> {
  return { result: "ok", data };
}

export function errorResponse(message: ErrorResponseMessage): ErrorResponse {
  return { result: "error", error: message };
}

export function responseMessageToError(error: ErrorResponseMessage) {
  const e = new Error(error.message);

  (e as ErrorResponseMessage).userMessage = error.userMessage;
  (e as ErrorResponseMessage).status = error.status;
  (e as ErrorResponseMessage).code = error.code;

  throw e;
}

export function responseToError(response: ErrorResponse) {
  responseMessageToError(response.error);
}
