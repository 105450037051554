import { get } from "svelte/store";
import { errorResponse } from "../response-types";
import type { SWRStore } from "./store";
import { StoreOptions, createStore } from "./store";
import type { SWR } from "./swr";
import { buildNotAttachedError, sessionKey } from "./swr";

export type PreloadFn = <T>(
  key: string | ((...args: any[]) => Promise<string>),
  options?: StoreOptions<T>
) => Promise<SWRStore<T>>;

/* Copied from @sapper/common */
export interface PreloadContext {
  fetch: (url: string, options?: any) => Promise<any>;
  error: (statusCode: number, message: Error | string) => void;
  redirect: (statusCode: number, location: string) => void;
}

export type LoaderFn<T> = (
  preload: PreloadFn,
  page: PageContext,
  session: any,
  preloadContext: PreloadContext
) => T | Promise<T>;

type LoadResource =
  | string
  | {
      /* Where to load the resource from */
      path: string;
      /* This is concated to `path` to form the full path. It is used to load different
       * parts of a larger data structure, for example pages in a paged list.
       * Using `loadResources` to later load a different subpath allows for integrating
       * the additional data into the already loaded one. */
      subpath?: string;
      options?: StoreOptions<any> & {
        /* By default you will get a store with `error` set on failure, but setting `errorPageOnFailure`
         * to true makes the error page will display instead, if the resource loading fails. */
        errorPageOnFailure?: boolean;
        /* Do not log any of these errors if they occur */
        errorCodesToIgnore?: string[];
      };
    };

type PreloadParameterContext = {
  params: Record<string, string | string[]>;
  path: string;
  query: Query;
  session: any;
};

type PreloadResource =
  | LoadResource
  | ((params: Record<string, any>) => LoadResource);

type PreloadParameter = (
  ctx: PreloadParameterContext
) => string | number | undefined;

export type ResolvedPromise<T> = T extends PromiseLike<infer U> ? U : T;

export type PageParams = Record<string, string | string[]>;
export type Query = Record<string, string | string[]>;

export interface PageContext {
  host: string;
  path: string;
  params: PageParams;
  query: Query;
  /** `error` is only set when the error page is being rendered. */
  error?: Error;
}

/**
 * Returns a preload function that will load the specified resources and
 * place them in variables corresponding to the keys of `resources`.
 *
 * On a server-side-rendered page, the returned store(s) will typically contain
 * the data immediately. On client-side navigation, it may or may not contain
 * data (depending on whether it was found in the cache). A fetch will be triggered
 * in the background and the store will be updated once the fetch completes.
 *
 * See also the description in the [README](../../README.md)
 */
export function preloadResources(
  resources: Record<string, PreloadResource>,
  parameters: Record<string, PreloadParameter> = {}
) {
  return buildPreloadFunction(
    async (preload, page, session, preloadContext) => {
      const context = {
        params: page.params,
        path: page.path,
        query: page.query,
        session,
      };

      try {
        const resolvedParameters = mapValues(
          (param: PreloadParameter) => param(context),
          parameters
        );

        return {
          ...resolvedParameters,
          ...(await asyncMapValues<PreloadResource, SWRStore<any>>(
            (pathStrOrFn: PreloadResource) => {
              const path =
                typeof pathStrOrFn === "function"
                  ? pathStrOrFn(resolvedParameters)
                  : pathStrOrFn;

              if (typeof path === "string") {
                return preload(path);
              } else {
                return preload(
                  path.path + (path.subpath || ""),
                  path.options
                ).then((store) => {
                  if (path.options?.errorPageOnFailure) {
                    const data = get(store);

                    if (data.error) {
                      preloadContext.error(
                        data.error.status || 500,
                        /* It looks like if we pass an Error here, it will remove all attributes except for message,
                           so we need to encode the whole error into a JSON so the error page gets the correct data.
                        */
                        JSON.stringify(data.error)
                      );
                    }
                  }

                  return store;
                });
              }
            },
            resources
          )),
        };
      } catch (e: any) {
        const queryEntries = Object.entries(page.query);

        e.message = `While preloading ${page.path}${
          queryEntries.length ? ` (query: ${JSON.stringify(queryEntries)})` : ``
        }: ${e.message}`;

        throw e;
      }
    }
  );
}

export function loadResource(
  resource: LoadResource,
  swrInstance?: SWR
): SWRStore<any> {
  return loadResources({ resource }, swrInstance).resource;
}

/** Similar to `preloadResources` but used for loading data after the page has loaded,
 * for example to load a second page of search results.
 */
export function loadResources<T extends Record<string, LoadResource>>(
  resources: T,
  swrInstance?: SWR
): Record<keyof T, SWRStore<any>> {
  const swr =
    swrInstance ||
    (typeof __SAPPER__ !== "undefined" &&
      __SAPPER__.session &&
      (__SAPPER__.session[sessionKey] as SWR));

  if (
    (typeof window !== "undefined" || process.env.NODE_ENV == "test") &&
    !swr
  ) {
    throw buildNotAttachedError();
  }

  function load(path: string, options?: StoreOptions<any>) {
    if (typeof window !== "undefined" || process.env.NODE_ENV == "test") {
      return (swr as SWR).load(path, options);
    } else {
      return createStore(path, {
        ...options,
        fetcher: () => ({
          cancel: () => {},
          response: Promise.resolve(
            errorResponse({
              message: `Stores returned by loadResources (${path}) should not be accessed on the server side.`,
            })
          ),
        }),
      });
    }
  }

  return mapValues((path: LoadResource) => {
    if (typeof path === "string") {
      return load(path);
    } else {
      return load(path.path + (path.subpath || ""), path.options);
    }
  }, resources);
}

async function asyncMapValues<From, To>(
  transform: (from: From) => Promise<To>,
  object: Record<string, From>
): Promise<Record<string, To>> {
  const res: Record<string, To> = {};

  await Promise.all(
    Object.entries(object).map(async ([key, value]) => {
      res[key] = await transform(value);
    })
  );

  return res;
}

function mapValues<Map extends Record<string, From>, From, To>(
  transform: (from: From) => To,
  object: Map
): Record<keyof Map, To> {
  const res: Record<keyof Map, To> = {} as any;

  Object.entries(object).map(async ([key, value]) => {
    res[key as keyof Map] = transform(value);
  });

  return res;
}

export function buildPreloadFunction<
  Loader extends LoaderFn<PreloadedData>,
  PreloadedData = ResolvedPromise<ReturnType<Loader>>
>(loader: Loader) {
  return async function preload(
    this: PreloadContext,
    page: PageContext,
    session: any
  ): Promise<PreloadedData> {
    const swr: SWR = session[sessionKey];
    const preload: PreloadFn = swr.preload.bind(swr);

    if (!preload) {
      throw buildNotAttachedError();
    }

    const data = await loader(preload, page, session, this);

    // Sapper uses devalue which does not call toJSON for us, so we need to do
    // that ourselves on the server
    const shouldSerialize = !(process as any).browser;

    if (shouldSerialize) {
      for (let [key, val] of Object.entries(data as Record<string, any>)) {
        if (val?.toJSON) {
          (data as any)[key] = val.toJSON();

          // We still need to expose the subscribe method for SSR to work. By
          // making it non-enumerable we keep it away from devalue
          Object.defineProperty((data as any)[key], "subscribe", {
            value: val.subscribe,
            enumerable: false,
            configurable: false,
            writable: false,
          });
        }
      }
    }

    return data;
  };
}
