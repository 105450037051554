import { logError, LoggableError, warn } from "@crown/logging";
import { get } from "svelte/store";
import { createCache } from "./cache";
import type { Fetcher } from "./fetcher";
import { createFetcher } from "./fetcher";
import type { StoreOptions, SWRStore } from "./store";
import { createStore } from "./store";

const defaultFetcher = createFetcher();

export const sessionKey = "_swr_";

declare const process: {
  browser: boolean;
  env: Record<string, string>;
};

export class SWR {
  cache = createCache<SWRStore<any>>();

  async preload<T>(
    key: string | ((...args: any[]) => Promise<string>),
    options: StoreOptions<T> & { errorCodesToIgnore?: string[] } = {}
  ) {
    if (typeof key === "function") key = await key();

    const store = this.getStore<T>(key as string, options);

    try {
      await store.revalidate();
    } catch (e) {
      const error = e as LoggableError;

      if (!(error.code && options.errorCodesToIgnore?.includes(error.code))) {
        /* preload may not throw an error because sapper handles it so badly. 
           instead, leave the store in the failed state */
        error.message = `While preloading ${key}${
          this.localeCode ? ` in locale ${this.localeCode}` : ""
        }: ${error.message}`;

        logError(error);
      }
    }

    return store;
  }

  constructor(
    private fetcher: Fetcher = defaultFetcher,
    private localeCode?: string
  ) {}

  /** Load is like preload but synchronous and returns a store that is initially `loading` and only resolves later */
  load<T>(key: string, options: StoreOptions<T> = {}) {
    if (key.startsWith("/") && typeof document != "undefined") {
      // if we set a different base path, e.g. when locales are on different paths
      if (document.URL != document.baseURI) {
        const basePath = new URL(document.baseURI).pathname;

        if (!key.startsWith(basePath)) {
          key = basePath + key.substring(1);
        } else {
          warn(
            `No need to start SWR load path ${key} with base path ${basePath}`
          );
        }
      }
    }

    const store = this.getStore<T>(key, options);

    if (typeof window != "undefined" || process.env.NODE_ENV == "test") {
      store.revalidate().catch(logError);
    }

    return store;
  }

  hasStore(key: string): boolean {
    return !!this.cache.get(key);
  }

  getStore<T>(key: string, options: StoreOptions<T> = {}): SWRStore<T> {
    let store = this.cache.get(key) as SWRStore<T>;

    if (!store) {
      store = createStore<T>(key, {
        fetcher: this.fetcher,
        localeCode: this.localeCode,
        ...options,
      });

      this.cache.set(key, store);
    }

    return store;
  }

  attachToSession(session: any = undefined) {
    if (typeof __SAPPER__ !== "undefined") {
      if (!session) {
        session = __SAPPER__.session = __SAPPER__.session || {};
      }

      // Deserialize preloaded data if available

      if (__SAPPER__.preloaded) {
        for (let level in __SAPPER__.preloaded) {
          if (__SAPPER__.preloaded[level]) {
            let entries = Object.entries(__SAPPER__.preloaded[level]);

            for (let [key, val] of entries) {
              if (isSwrState(val)) {
                let { __swr_key, ...initialState } = val as any;

                const store = this.getStore(__swr_key, {
                  initialState,
                  localeCode: this.localeCode,
                });

                __SAPPER__.preloaded[level][key] = store;

                const { loadTime } = get(store);

                if (loadTime && process.browser) {
                  console.debug(`${__swr_key} ${loadTime}ms`);
                }

                this.cache.set(__swr_key, store);
              }
            }
          }
        }
      }
    }

    if (!session) {
      throw new Error("No session provided");
    }

    // Hide session property from devalue
    Object.defineProperty(session, sessionKey, {
      value: this,
      enumerable: false,
      configurable: false,
      writable: false,
    });
  }
}

export function createSWR(
  {
    fetcher,
    localeCode,
  }: {
    fetcher?: Fetcher;
    localeCode?: string;
  } = {
    fetcher: defaultFetcher,
  }
) {
  return new SWR(fetcher, localeCode);
}

function isSwrState(obj: any) {
  return obj != null && typeof obj === "object" && "__swr_key" in obj;
}

export function buildNotAttachedError() {
  return new Error(
    "You need to call `swr.attachToSession() before using swrPreload`"
  );
}
