import { debug, logError } from "@crown/logging";

const UTM_KEY = "utm";

/** Stores the query string the user originally arrived on the site with. */

export function getUtm(): string | undefined {
  if (typeof localStorage !== "undefined") {
    try {
      return localStorage.getItem(UTM_KEY) || undefined;
    } catch (error: any) {
      logError(error);
    }
  }

  return undefined;
}

export function setUtm(utmString: string): void {
  debug(`Storing UTM: ${utmString}`);

  if (typeof localStorage !== "undefined") {
    try {
      localStorage.setItem(UTM_KEY, utmString);
    } catch (error: any) {
      logError(error);
    }
  }
}

export function clearUtm(): void {
  if (typeof localStorage !== "undefined") {
    try {
      localStorage.removeItem(UTM_KEY);
    } catch (error: any) {
      logError(error);
    }
  }
}
