import "./instant-navigation";
import { hydrate, interact, lazy } from "./hydrate";
import { route } from "./stores";
import { initRouter } from "./router";

export function init(svelteContext: Map<string, any>) {
  hydrate(svelteContext);

  route.subscribe(() => {
    lazy(svelteContext);
    interact(svelteContext);
  });

  initRouter(svelteContext);
}
