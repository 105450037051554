import { logError } from "@crown/logging";
import type { Counter as CounterClass, Gauge as GaugeClass } from "prom-client";
import { toSnakeCase } from "./toSnakeCase";

export type Counter = Pick<CounterClass, "inc">;
export type Gauge = Pick<GaugeClass, "startTimer" | "set">;

// We don't want to run this on Vite because when it hot reloads,
// the counters fail to initialize. Ideally, we should test if running
// on Vite instead.
let isEnabled = !!process.env.FLY_APP_NAME;

declare const process: { browser: boolean; env: Record<string, string> };

let CounterImpl: typeof CounterClass;
let GaugeImpl: typeof GaugeClass;

if (!process.browser) {
  // it happens a lot that this file is present in some kind of dependency chain
  // and is therefore executed on the client, even if there is not a single call
  // to it on the client (this is because tree shaking will not remove initializing
  // code in modules).
  // these dependencies can be very hard to avoid, so just support using the module
  // on the client. hence the dynamic import.
  import("prom-client").then(({ Counter, Gauge }) => {
    CounterImpl = Counter;
    GaugeImpl = Gauge;
  }, logError);
}

function checkNotOnBrowser() {
  if (process.browser) {
    throw new Error(`Prometheus facade should not be imported on the client`);
  }
}

export function getCounter(opts: {
  name: string;
  help: string;
  labelNames?: string[];
}): Counter {
  let delegate: undefined | CounterClass;

  const counter = {
    didFail: false,

    createDelegate() {
      if (!delegate && isEnabled && CounterImpl && !this.didFail) {
        try {
          delegate = new CounterImpl(opts);
        } catch (e: any) {
          e.message = `While creating counter ${opts.name}: ${e.message}`;
          logError(e);
          this.didFail = true;
        }
      }
    },

    inc(
      labels?: Partial<Record<string, string | number>> | number,
      value?: number
    ) {
      this.createDelegate();

      if (typeof labels === "number" || typeof labels == "undefined") {
        return delegate?.inc(labels);
      } else {
        delegate?.inc(labels, value);
      }
    },
  };

  checkNotOnBrowser();

  if (isEnabled) {
    return counter;
  } else {
    verifySnakeCase(opts.name);

    return counter;
  }
}

export function getGauge(opts: {
  name: string;
  help: string;
  labelNames?: string[];
}): Gauge {
  let delegate: undefined | GaugeClass;

  const gauge = {
    didFail: false,

    createDelegate() {
      if (!delegate && isEnabled && GaugeImpl && !this.didFail) {
        try {
          delegate = new GaugeImpl(opts);
        } catch (e: any) {
          e.message = `While creating gauge ${opts.name}: ${e.message}`;
          logError(e);
          this.didFail = true;
        }
      }
    },

    startTimer(labels?: Partial<Record<string, string | number>>) {
      this.createDelegate();

      if (delegate) {
        return delegate.startTimer(labels);
      } else {
        let startTime = Date.now();

        return () => Date.now() - startTime;
      }
    },

    set(
      labels: Partial<Record<string, string | number>> | number,
      value?: number
    ) {
      this.createDelegate();

      if (typeof labels === "number") {
        return delegate?.set(labels);
      } else {
        return delegate?.set(labels, value!);
      }
    },
  };

  checkNotOnBrowser();

  if (isEnabled && GaugeImpl) {
    try {
      return new GaugeImpl(opts);
    } catch (e: any) {
      e.message = `While creating gauge ${opts.name}: ${e.message}`;
      logError(e);

      return gauge;
    }
  } else {
    verifySnakeCase(opts.name);

    return gauge;
  }
}

function verifySnakeCase(name: string) {
  if (toSnakeCase(name) != name) {
    logError(
      new Error(
        `Counter name ${name} is not snake case. This is not allowed in production.`
      )
    );
  }
}
