import type { Context, ErrorListener } from ".";

export const logToConsole: ErrorListener = (error, level, context) => {
  let message =
    typeof error == "string"
      ? error
      : "stack" in error && error.stack != undefined
      ? [error.message, ...error.stack.split("\n").slice(1)].join("\n")
      : error.message
      ? error.message
      : error.toString();

  if (context) {
    message +=
      (message?.includes("\n") ? "\n" : " ") + contextToString(context);
  }

  // colors
  // https://github.com/mochajs/mocha/blob/9e95d36e4b715380cef573014dec852bded3f8e1/lib/reporters/base.js#L48
  const colors = {
    success: 32,
    warn: 33,
    error: 31,
    info: 1,
  };
  const prefix = (label: keyof typeof colors) =>
    "\u001b[" + colors[label] + "m" + String(`[${label}]`) + "\u001b[0m";
  if (level == "error") {
    console.error(`${prefix("error")} ${message}`);
  } else if (level == "warn") {
    console.warn(`${prefix("warn")} ${message}`);
  } else {
    console.log(`${prefix("info")} ${message}`);
  }
};

function contextToString(context: Context) {
  return Object.entries(context)
    .filter(([_key, value]) => value != undefined && value != "")
    .map(([key, value]) => `${key}: ${value}`)
    .join(", ");
}
