import { SWR, createFetcher } from "@crown/data";

declare const process: { browser: boolean };

const generateSWR = () =>
  new SWR(
    process.browser
      ? undefined
      : createFetcher({
          fetch: () => {
            throw new Error("SWR fetches should not be done on server");
          },
        })
  );

/** Global SWR instance for use with e.g. aggregateResources or loadResources.
 * (in crown v1, this exists in a global session, but in v2 we have to pass it in explicitly)
 * On the server, it is not shared, on the client it is.
 */
export let getSWR =
  typeof window != "undefined" ? memoize(generateSWR) : generateSWR;

function memoize<T>(fn: () => T) {
  let cache: T;

  return () => {
    if (!cache) {
      cache = fn();
    }

    return cache;
  };
}
