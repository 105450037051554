import { Readable, writable } from "svelte/store";

export type Navigating = { from: URL; to: URL } | null;

const store = writable<Navigating>(null);

export const setNavigating = store.set;
export const navigating: Readable<Navigating> = {
  subscribe: (subscriber: any, invalidate: any) => {
    return store.subscribe(subscriber, invalidate);
  },
};
