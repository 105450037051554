import { error } from "@crown/logging";

// This refers to a map of paths referring to dynamic imports which is generated at build time
import crownComponents from "$crown/components";

const instances = new WeakMap<HTMLElement, Promise<any>>();
const lazyObserved = new Set<Element>();

export function lazy(svelteContext: Map<string, any>) {
  // Attaches an IntersectionObserver to all children of a crown-component to hydrate it only when it becomes visible
  const lazyObserver = new IntersectionObserver((entries) =>
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const el = entry.target.closest("crown-component");
        if (el) {
          if (!instances.get(el as HTMLElement)) {
            attach({
              element: el as HTMLElement,
              hydration: true,
              mode: "lazy",
              svelteContext,
            });
          }
        }
      }
    })
  );

  document
    .querySelectorAll("crown-component[data-mode=lazy]:not([data-hydrated])")
    .forEach((el) => {
      if (!lazyObserved.has(el)) {
        el.querySelectorAll("*").forEach((childEl) =>
          lazyObserver.observe(childEl)
        );
        lazyObserved.add(el);
      }
    });
}

export function hydrate(svelteContext: Map<string, any>) {
  // Hydrates a server side rendered page by hydrating Svelte components where they are rendered
  const promises = new Array(
    ...document.querySelectorAll("crown-component[data-mode=mount]")
  ).map((el) =>
    attach({
      element: el as HTMLElement,
      hydration: true,
      mode: "hydrate",
      svelteContext,
    })
  );

  // tests can use this to await the page being interactive
  Promise.all(promises).then(() =>
    document.documentElement.setAttribute("data-page-interactive", "true")
  );
}

let unsubscribes = new Map<HTMLElement, () => void>();

const interactionEvents = {
  click: PointerEvent,
  focus: FocusEvent,
  mouseenter: MouseEvent,
  touchstart: typeof TouchEvent !== "undefined" ? TouchEvent : undefined,
};

export function interact(svelteContext: Map<string, any>) {
  unsubscribes.forEach((unsubscribe) => unsubscribe());

  function load(event: PointerEvent | FocusEvent | MouseEvent | TouchEvent) {
    if (event.target) {
      const el = (event.target as HTMLElement).closest("crown-component");
      if (el) {
        let promise = instances.get(el as HTMLElement);
        if (!promise) {
          promise = attach({
            element: el as HTMLElement,
            hydration: true,
            mode: "interact",
            svelteContext,
          });
        }

        if (promise) {
          // We cannot re-dispatch the same event again once handlers have been attached, so we have to clone it
          let clonedEvent: Event | null = null;
          if (event.type in interactionEvents) {
            const constructor =
              interactionEvents[event.type as keyof typeof interactionEvents];

            if (constructor) {
              clonedEvent = new constructor(
                event.type,
                event as PointerEventInit &
                  FocusEventInit &
                  MouseEventInit &
                  TouchEventInit
              );
            }
          }

          promise.then(() => {
            const unsubscribe = unsubscribes.get(el as HTMLElement);
            if (unsubscribe) unsubscribe();

            if (clonedEvent)
              (event.target as HTMLElement).dispatchEvent(clonedEvent);
          });
        }
      }
    }
  }

  document
    .querySelectorAll(
      "crown-component[data-mode=interact]:not([data-hydrated])"
    )
    .forEach((el) => {
      const childEls: HTMLElement[] = [];
      el.querySelectorAll("*").forEach((childEl) =>
        childEls.push(childEl as HTMLElement)
      );

      childEls.forEach((childEl) =>
        Object.keys(interactionEvents).forEach((key) =>
          childEl.addEventListener(key as keyof typeof interactionEvents, load)
        )
      );

      unsubscribes.set(el as HTMLElement, () => {
        childEls.forEach((childEl) =>
          Object.keys(interactionEvents).forEach((key) =>
            childEl.removeEventListener(
              key as keyof typeof interactionEvents,
              load
            )
          )
        );
      });
    });
}

export function attach({
  element,
  hydration = true,
  mode,
  svelteContext,
}: {
  element: HTMLElement;
  hydration: boolean;
  mode: string;
  svelteContext: Record<string, any>;
}) {
  const componentPath = element.dataset.component;

  if (!componentPath) {
    return;
  }

  const componentImport = crownComponents[componentPath];

  if (typeof componentImport === "function") {
    const promise = componentImport();

    let props: Record<string, any>;
    try {
      props = JSON.parse(decodeURIComponent(element.dataset.props || "{}"));
    } catch (e) {
      error(`Could not parse props of component "${componentPath}"`);
      return;
    }

    const instance = promise.then(
      ({ default: Component }) =>
        new Component({
          target: element,
          props,
          hydrate: true,
          intro: !hydration,
          context: svelteContext,
        })
    );
    instances.set(element, instance);
    instance.then(() => (element.dataset["hydrated"] = mode));
    return instance;
  } else {
    error(`"${componentPath}" is not a valid Crown component`);
  }
}

export function detach(el: HTMLElement) {
  const promise = instances.get(el);
  if (!promise) {
    return;
  }

  promise
    .then((instance) => instance.$destroy({ runOutro: true }))
    .then(() => el.remove());
}

export function update(current: HTMLElement, next: HTMLElement) {
  const promise = instances.get(current);
  if (!promise) {
    return;
  }

  current.dataset.props = next.dataset.props;

  promise.then((instance) =>
    instance.$set(JSON.parse(decodeURIComponent(next.dataset.props || "")))
  );
}
