import { writable } from "svelte/store";

export interface Route {
  url: URL;
}

const store = writable<Route>({
  url: new URL(window.location.href),
});

export const setRoute = store.set;

export const route = {
  subscribe: store.subscribe,
};
