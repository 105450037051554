import { goto } from "./navigation";
import { preload } from "./router";

// This implements preloading when hovering or touching all anchor links with
// a target URL on the same origin, which results in close to instant navigation
// when the link is pressed

let mouseoverTimer: ReturnType<typeof setTimeout> | undefined;
let isTouching = false;
let delayOnHover = 65;

const eventListenersOptions = {
  capture: true,
  passive: true,
};

document.addEventListener(
  "touchstart",
  touchstartListener,
  eventListenersOptions
);
document.addEventListener("touchend", touchendListener, eventListenersOptions);
document.addEventListener(
  "touchcancel",
  touchendListener,
  eventListenersOptions
);

document.addEventListener(
  "mouseover",
  mouseoverListener,
  eventListenersOptions
);

function touchstartListener(event: TouchEvent) {
  if (isElement(event.target)) {
    const linkElement = event.target.closest("a");
    isTouching = true;

    if (linkElement && shouldPreload(linkElement)) {
      preload(linkElement.href);
    }
  }
}

function touchendListener() {
  isTouching = false;
}

function mouseoverListener(event: MouseEvent) {
  if (isElement(event.target)) {
    const linkElement = event.target.closest("a");

    if (isTouching || !linkElement || !shouldPreload(linkElement)) {
      return;
    }

    linkElement.addEventListener("mouseout", mouseoutListener, {
      passive: true,
    });

    mouseoverTimer = setTimeout(() => {
      preload(linkElement.href);
      mouseoverTimer = undefined;
    }, delayOnHover);
  }
}

function mouseoutListener(event: MouseEvent) {
  if (
    isElement(event.relatedTarget) &&
    isElement(event.target) &&
    event.target.closest("a") == event.relatedTarget.closest("a")
  ) {
    return;
  }

  if (mouseoverTimer) {
    clearTimeout(mouseoverTimer);
    mouseoverTimer = undefined;
  }
}

function shouldPreload(linkElement: HTMLAnchorElement) {
  if (
    !linkElement?.href ||
    linkElement.origin !== location.origin ||
    linkElement.attributes.getNamedItem("data-prefetch")?.value == "false"
  ) {
    return false;
  }

  if (
    (linkElement.hash &&
      linkElement.pathname + linkElement.search ===
        location.pathname + location.search) ||
    linkElement.attributes.getNamedItem("data-prefetch")?.value == "false"
  ) {
    return false;
  }

  return true;
}

document.addEventListener("click", async function (event) {
  if (isElement(event.target)) {
    const link = event.target.closest("a");

    if (
      link &&
      !link.getAttribute("href")?.startsWith("#") &&
      link.origin === location.origin &&
      !link.hasAttribute("download") &&
      link.getAttribute("rel") !== "external"
    ) {
      event.preventDefault();

      goto(link.href, {
        replaceState: typeof link.dataset.crownReplace !== "undefined",
      });
    }
  }
});

function isElement(value: any): value is HTMLElement {
  return value?.innerText != undefined;
}
