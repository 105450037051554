import { get, Readable, writable } from "svelte/store";
import { logError } from "@crown/logging";
import type { ErrorResponseMessage } from "../response-types";
import { responseMessageToError } from "../response-types";
import type { Fetcher, FetchResponse, Method } from "./fetcher";
import { inFocus, isOnline } from "./invalidation";

/** Note that this cannot be much lower without getting multiple fetches on every page load. */
const DEFAULT_TTL_S = 5;

let doRevalidateOnSubscribe = typeof window !== "undefined";

/** used by the tests */
export const setRevalidateOnSubscribe = (value: boolean) =>
  (doRevalidateOnSubscribe = value);

declare const process: {
  browser: boolean;
  env: Record<string, string>;
};

export interface SWRStore<Data>
  extends Readable<State<Data>>,
    StoreOperations<Data> {}

/**
 * Stores returned by the SWR client contain this data structure. Typically, a store will first
 * not contain any data and be in the state `initial`. Meanwhile, a fetch is running in the background.
 * The store whill switch to the state `loaded` with the data set once the fetch completes.
 */
export interface State<Data> {
  /** Loading state */
  state: RequestState;

  /** The data loaded. If the fetch failed or the store is still loading, it might be undefined. */
  data?: Data;

  /** If the state is 'failed', `error` will contain the error. When error is set, you typically
   * want to display an error message to the user.
   */
  error?: ErrorResponseMessage;

  /** Timestamp indicating when the data becomes stale and should be refetched. */
  expires?: number;

  /** Time taken to load the data, for debugging purposes. */
  loadTime?: number;
}

export type RequestState = "initial" | "loading" | "loaded" | "failed";

export interface StoreOperations<Data> {
  /** Reload value from server if it is stale */
  revalidate(): Promise<Data>;

  /** Unconditionally reload value from server. */
  refresh(): Promise<Data>;

  /** Optimistically update value, then call server to perform the operation server-side */
  mutate(options: {
    update: (oldValue: Data) => Data;
    subpath?: string;
    method: Method;
    body?: object;
    query?: Record<string, string>;
    requireLoaded?: boolean;
  }): Promise<Data>;

  onPreAutoRevalidate(callback: () => void): void;
  onPostAutoRevalidate(callback: () => void): void;

  toJSON(): State<Data> & { __swr_key: string };
}

export interface StoreOptions<Data> {
  initialData?: Data;
  /** Default time to live in seconds. If not provided,
   * the fetcher's ttl (from the cache-control response header) */
  ttl?: number;
  initialState?: State<Data>;
  fetcher?: Fetcher;
  localeCode?: string;
}

function getExpires(ttl?: number) {
  return new Date().getTime() + (ttl != null ? ttl : DEFAULT_TTL_S) * 1000;
}

export function createStore<Data>(
  key: string,
  options: StoreOptions<Data> & { fetcher: Fetcher } /* fetcher mandatory */
): SWRStore<Data> & {
  subscribeWithoutLifecycle: (fn: (value: State<Data>) => void) => () => void;
} {
  if (typeof window == "undefined" && !key) {
    throw new Error(`Missing key`);
  }

  const fetcher = options.fetcher;
  const localeCode = options.localeCode;

  const initialState = options.initialState || {
    data: options.initialData,
    state: options.initialData ? "loaded" : "initial",
    expires: options.initialData ? getExpires(options.ttl) : 0,
    error: undefined,
  };
  const store = writable<State<Data>>(initialState);

  const setState = (state: RequestState) =>
    store.update((rest) => ({ ...rest, state }));

  let refs = 0;

  function shouldRevalidate({
    state,
    expires,
  }: {
    state: RequestState;
    expires?: number;
  }) {
    if (state === "loaded" || state === "failed") {
      return !expires || new Date().getTime() >= expires;
    } else if (state === "initial") {
      return true;
    } else if (state === "loading") {
      return false;
    }

    throw new Error(`Unhandled state "${state}"`);
  }

  function toState<Data>(
    response: FetchResponse<Data>,
    options: StoreOptions<any>
  ): State<Data> {
    const expires = getExpires(options.ttl || response.ttl);

    /* Note that null is a valid response */
    const isEmptyResponse =
      response.result === "ok" && response.data === undefined;

    if (response.result === "ok" && !isEmptyResponse) {
      return {
        data: response.data,
        state: "loaded",
        error: undefined,
        expires,
      } as State<Data>;
    } else {
      if (isEmptyResponse) {
        logError(`Got ok response with no data from ${key}`);
      }

      return {
        data: undefined,
        state: "failed",
        error: (response.result == "error" && response.error) || {
          message: isEmptyResponse
            ? "No response data"
            : "Unexpected response shape",
        },
        expires,
      } as State<Data>;
    }
  }

  async function load(): Promise<Data> {
    const { data } = get(store);

    const startTime = new Date().getTime();

    setState("loading");

    const promise = fetcher(key, {
      headers: localeCode
        ? {
            locale: localeCode,
          }
        : {},
    }).response.then(
      (payload) => {
        const state = toState<Data>(payload, options);

        const loadTime = new Date().getTime() - startTime;
        state.loadTime = loadTime;
        store.set(state);

        if (loadTime && typeof window != "undefined") {
          console.debug(`${key} ${loadTime}ms`);
        }

        if (state.error) {
          throw responseMessageToError(state.error);
        }

        return state.data as Data;
      },
      (error) => {
        store.update((rest) => ({
          ...rest,
          expires: getExpires(options.ttl),
          state: "failed",
          error: toErrorResponseMessage(error),
        }));

        throw error;
      }
    );

    if (data !== undefined) {
      // we're not waiting, but make sure errors are not uncaught.
      promise.catch((e) => console.error(e));

      return data;
    } else {
      return promise;
    }
  }

  async function revalidate(): Promise<Data> {
    if (typeof window == "undefined" && process.env.NODE_ENV != 'test') {
      throw new Error(`Should not revalidate on server`);
    }

    return synchronize(async () => {
      const { state: currentState, data, expires, error } = get(store);

      if (shouldRevalidate({ state: currentState, expires })) {
        return load();
      } else {
        if (error) {
          return Promise.reject(error);
        } else if (data !== undefined) {
          return data as Data;
        } else {
          throw new Error(
            `Bug in SWR: ${JSON.stringify({
              key,
              currentState,
              data,
              error,
              expires,
            })}`
          );
        }
      }
    });
  }

  async function refresh(): Promise<Data> {
    return synchronize(load);
  }

  function synchronize<T>(op: () => Promise<T>) {
    mutex = mutex.catch(() => {}).then(op);

    return mutex;
  }

  let mutex: Promise<any> = Promise.resolve();

  /**
   * Call and endpoint that triggers a change the store data and reload the data from the response.
   */
  async function mutate({
    /* Appended to the original store path. */
    subpath,
    method,
    /* Request data to send */
    body,
    query,
    /* An optional method that specifies how to integrated the returned data into the data of the store.
     * If not specified, it is assumed that the response is the new store value. */
    update,
    requireLoaded,
  }: {
    subpath?: string;
    update: (oldValue: Data) => Data;
    method: Method;
    body?: object;
    query?: Record<string, string>;
    requireLoaded?: boolean;
  }) {
    return synchronize(() => {
      if (requireLoaded && get(store).state == "failed") {
        throw get(store).error || new Error("Was in error state");
      }

      let oldData: Data | undefined;

      store.update((value) => {
        oldData = value.data;

        return {
          ...value,
          data: value.data != null ? update(value.data) : value.data,
          state: "loading",
        };
      });

      let queryString = query
        ? "?" + new URLSearchParams(query).toString()
        : "";

      return fetcher(key + (subpath || "") + queryString, {
        method,
        body,
      }).response.then(
        (payload) => {
          const state = toState<Data>(payload, options);

          if (state.error) {
            state.data = oldData;
            delete state.expires;
          }

          store.set(state);

          if (state.error) {
            throw responseMessageToError(state.error);
          }

          return state.data as Data;
        },
        (error) => {
          store.update((rest) => ({
            ...rest,
            data: oldData,
            state: "failed",
            error: toErrorResponseMessage(error),
          }));

          throw error;
        }
      );
    });
  }

  function toErrorResponseMessage(error: any): ErrorResponseMessage {
    return {
      message: error.message,
      userMessage: (error as ErrorResponseMessage).userMessage,
      status: (error as ErrorResponseMessage).status,
      code: (error as ErrorResponseMessage).code,
      id: (error as ErrorResponseMessage).id,
    };
  }

  function subscribeWithLifecycle<T>(
    store: Readable<T>,
    getInterval: () => number
  ) {
    let unsubscribeInFocus: () => void;
    let unsubscribeIsOnline: () => void;

    return function subscribe(subscriber: any) {
      const unsubscribe = store.subscribe(subscriber);
      refs++;

      if (refs === 1 && typeof window !== "undefined") {
        let lastLoadAt = new Date().getTime();

        const resetTimer = () => (lastLoadAt = new Date().getTime());
        const noRecentLoad = () =>
          new Date().getTime() - lastLoadAt > getInterval();

        const revalidateNow = () => {
          resetTimer();

          preAutoRevalidate();

          revalidate().then((res) => {
            postAutoRevalidate();

            return res;
          }, logError);
        };

        unsubscribeInFocus = inFocus.subscribe((inFocus) => {
          if (inFocus && noRecentLoad()) {
            revalidateNow();
          }
        });

        unsubscribeIsOnline = isOnline.subscribe((isOnline) => {
          if (isOnline && noRecentLoad()) {
            revalidateNow();
          }
        });
      }

      if (refs === 1 && doRevalidateOnSubscribe) {
        revalidate().catch(logError);
      }

      return () => {
        unsubscribe();
        refs--;

        if (refs === 0) {
          unsubscribeInFocus && unsubscribeInFocus();
          unsubscribeIsOnline && unsubscribeIsOnline();
        }
      };
    };
  }

  let preAutoRevalidate = () => {};
  let postAutoRevalidate = () => {};

  return {
    subscribe: subscribeWithLifecycle(store, () =>
      get(store).error ? 1000 : 10000
    ),
    onPreAutoRevalidate: (callback: () => void) =>
      (preAutoRevalidate = callback),
    onPostAutoRevalidate: (callback: () => void) =>
      (postAutoRevalidate = callback),
    /* only used by the tests */
    subscribeWithoutLifecycle: (subscriber: (value: State<Data>) => void) => {
      revalidate().catch((e) => {
        if (process.env.NODE_ENV != "test") console.error(e);
      });
      return store.subscribe(subscriber);
    },
    toJSON: () => ({ ...get(store), __swr_key: key }),
    mutate,
    revalidate,
    refresh,
  };
}

export async function waitForValue<T>(store: Readable<State<T>>): Promise<T> {
  let unsubscribe: (() => any) | null = null;

  try {
    return await new Promise<T>((resolve, reject) => {
      unsubscribe = store.subscribe((data) => {
        if (data.state == "loading" || data.state == "initial") {
          // keep waiting
        } else if (data.data !== undefined) {
          resolve(data.data);
        } else if (data.error) {
          reject(data.error);
        } else {
          const { state, error } = data;

          throw new Error(
            `Unexpected store state: ${JSON.stringify({
              state,
              data: data.data !== undefined,
              error,
            })}`
          );
        }
      });
    });
  } finally {
    unsubscribe && (unsubscribe as () => any)();
  }
}
