export interface CacheInterface<T> {
  get(key: string): T | undefined;
  set(key: string, value: T): void;
}

export function createCache<T>(): CacheInterface<T> {
  const cache = new Map<string, T>();
  return {
    get(key: string) {
      return cache.get(key);
    },
    set(key: string, value: T) {
      cache.set(key, value);
    },
  };
}
