import { get } from "svelte/store";
import { history } from "./history";
import { transformDocument } from "./morph";
import { setNavigating, navigating } from "./stores/navigating";
import { setRoute, route } from "./stores/route";
import { debug, error, toLoggableError, warn } from "@crown/logging";

export const prefetched = new Set();
export const pending = new Map<
  string,
  Promise<{ response: Response; document: Document }>
>();

let currentController: AbortController;
async function navigate(url: string) {
  const controller = new AbortController();
  currentController?.abort();
  currentController = controller;

  return loadDocument(url, controller.signal);
}

function getMaxAge(cacheControl: string) {
  const match = cacheControl.match(/max-age=([0-9]+)/);
  return match ? parseInt(match[1], 10) || 0 : 0;
}

function invalidatePrefetched(url: string, response: Response) {
  const age = parseInt(response.headers.get("age") || "", 10) || 0;
  const maxAge = getMaxAge(response.headers.get("cache-control") || "");

  const expiry = maxAge - age;
  setTimeout(() => {
    prefetched.delete(url);
  }, expiry * 1000);
}

async function load(url: string, signal?: AbortSignal) {
  return fetch(url, { signal });
}

async function loadDocument(url: string, signal?: AbortSignal) {
  const response = await load(url, signal);
  invalidatePrefetched(url, response);
  const html = await response.text();
  const parser = new DOMParser();
  const document = parser.parseFromString(html, "text/html");
  return {
    document,
    response,
  };
}

export function preload(url: string) {
  if (prefetched.has(url)) {
    return;
  }

  let promise = loadDocument(url);

  pending.set(url, promise);
  promise.then(() => pending.delete(url));

  prefetched.add(url);
}

function getPendingResponse(url: string) {
  return pending.get(url);
}

export function initRouter(svelteContext: Map<string, any>) {
  const cache = {} as Record<string, Document>;

  // Listen to updates to history so we can update the route store, do intented scrolling behaviour etc
  history.listen(async ({ url, action, noScroll }) => {
    const previousUrl = get(route).url;

    debug(`Storing doc in cache as ${previousUrl}.`);
    cache[previousUrl.toString()] = document.cloneNode(true) as Document;

    const currentNavigation = {
      from: previousUrl,
      to: url,
    };
    setNavigating(currentNavigation);

    try {
      // If we have the current rendered document already in the in-memory cache we can start rendering synchronously and refetch the page in the background
      if (action !== "PUSH") {
        const cachedDocument = cache[url.toString()];

        if (cachedDocument) {
          debug(`Found cached doc for ${url}. Transforming...`);

          transformDocument(cachedDocument, svelteContext);

          delete cache[url.toString()];
        }
      }

      let promise = getPendingResponse(url.toString());

      if (!promise) {
        promise = navigate(url.toString());
      } else {
        debug(`Got prefetched document ${url}`);
      }

      const { response, document } = await promise;

      debug(`Loaded ${url}. Transforming...`);

      if (get(navigating) === currentNavigation) {
        // Do minimal DOM changes to end up on the new page
        transformDocument(document, svelteContext);

        // construct a new URL without hash data
        const currentUrl = new URL(`${url.origin}${url.pathname}${url.search}`);

        if (currentUrl.toString() !== response.url) {
          history.replace(response.url, undefined, false);
        }
      } else {
        warn(
          `Another navigation (to ${
            get(navigating)?.to
          }) occurred before ${url} had loaded`
        );

        return;
      }
    } catch (e: any) {
      error(toLoggableError(e, { messagePrefix: "Navigation failed" }));

      window.location.reload();

      return;
    }

    if (action === "PUSH" && !noScroll) {
      // Morphdom wont touch the scroll position, meaning that any scroll position will be retained when moving to the next page
      // This is not a great user experience, moving to a new page usually means you want to scroll to the top of the page
      // By using this data-attribute the user can specify which elements should be scrolled to top when moving to a new page
      document
        .querySelectorAll("[data-crown-reset-scroll]")
        .forEach((el) => el.scrollTo(0, 0));
      document.scrollingElement?.scrollTo(0, 0);
    }

    setRoute({ url });
    setNavigating(null);

    if (action === "PUSH" && url.hash) {
      // If the user has clicked a link with a # we try to find the element and scroll it into view (as the native behaviour is)
      document.querySelector(url.hash)?.scrollIntoView();
    }
  });
}
