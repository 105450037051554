export const defaultStoryblokPath = "https://a.storyblok.com/f/132956";

export function shortenStoryblokImageUrl(url: string) {
  if (url.startsWith(defaultStoryblokPath)) {
    return url.substring(defaultStoryblokPath.length);
  } else {
    return url;
  }
}

export function expandStoryblokImageUrl(url: string) {
  if (url.startsWith("/")) {
    return defaultStoryblokPath + url;
  } else {
    return url;
  }
}
