import type { SWR } from "@crown/data";
import { getContext } from "svelte";
import { SHOPPING_LIST_CONTEXT_KEY } from "../context-keys";
import type { Storefront } from "../types/Storefront";
import { getProductListStore } from "./product-list";
import { normalizePath } from "../url-builder";

export function createShoppingListStore({
  swr,
  basePath,
}: {
  swr?: SWR;
  basePath: string;
}): Storefront.ShoppingListStore {
  return getProductListStore<
    Storefront.ShoppingListItem,
    Storefront.ShoppingList<Storefront.ShoppingListItem>,
    any
  >(
    normalizePath(basePath, "/resources/shopping-list"),
    (cart: any) => cart,
    swr
  );
}

export function getShoppingListStore(): Storefront.ShoppingListStore {
  const store = getContext(
    SHOPPING_LIST_CONTEXT_KEY
  ) as Storefront.ShoppingListStore;

  if (import.meta.env.DEV && !store) {
    throw new Error("Shopping list not present in context.");
  }

  return store;
}
