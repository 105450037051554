import { navigating } from "./stores";
import { get } from "svelte/store";
import { history } from "./history";

export interface GotoOptions {
  replaceState?: boolean;
  noscroll?: boolean;
  state?: any;
}

export function goto(href: string, options?: GotoOptions) {
  // If a navigation is already ongoing, we don't want to push multiple entries to the history but just replace the latest one
  if (options?.replaceState || get(navigating)) {
    history.replace(href, options?.state);
  } else {
    history.push(href, options?.state, !!options?.noscroll);
  }
}
