import type { LoaderFunction } from "@crown/jewels/server";

import { getContext } from "svelte";
import { pathToUrl } from "./links";

import type se from "../i18n/hsse.json";
import { LOCALIZATION_CONTEXT_KEY } from "./context-keys";

declare const process: { isDevelopment: boolean; browser: boolean };

type RawLocalizedStrings = typeof se;

/** version is used to detect if the strings have changed */
export type LocalizedStrings = RawLocalizedStrings & { version: number };


/**
 * The localization is retrieved from the Svelte context.
 *
 * On the server side, it should be put there by a handle function in entry-server.
 *
 * Therefore, on the client side we load resources/strings.js.ts as a JavaScript script
 * from the layout. entry-server should then put it in the context, retrieving it using
 * getLocalizationOnClientInit below.
 *
 * If you are on the server side and not in component
 * initialization, use getLocalizedStrings() in localized-strings
 */

export default function localization(): LocalizedStrings {
  const strings = getContext(LOCALIZATION_CONTEXT_KEY) as LocalizedStrings;

  if (import.meta.env.DEV && !strings) {
    throw new Error("Localization not present in context.");
  }

  return strings;
}

export function format(string: string, params: (string | number)[]) {
  return string.replace(/{(\d+)}/g, (match, number) => {
    return typeof params[number] != "undefined" ? params[number] + "" : match;
  });
}

/**
 * Should be called by entry-client to transfer the strings from  set the localization in the context
 * for the server side.
 */
export function getLocalizationOnClientInit(): LocalizedStrings {
  const localization = window["strings"];

  if (!localization) {
    throw new Error("strings not present.");
  }

  return localization;
}

/**
 * For localized path names we support all language variations on all sites.
 * If you arrive on the wrong language version, we want to redirect to the current one.
 * This wraps a preview function to do that
 */
export function canonicalUrlRedirector(
  loader: LoaderFunction,
  pageKey: string
) {
  const canonicalUrlLoader: LoaderFunction = (options) => {
    const { locals } = options;
    const { strings, locale, request } = locals;
    const localizedPage = strings.routes[pageKey];

    if (process.isDevelopment && !localizedPage) {
      throw new Error(
        `Missing key route.${pageKey} in localization ${locale.localeCode}.`
      );
    }

    const canonicalRoute = `/${localizedPage}`;

    const url = new URL(request.url);

    const newUrl = pathToUrl(canonicalRoute + url.search, {
      request,
      locals,
    });

    if (url.pathname !== newUrl.pathname) {
      return Response.redirect(newUrl);
    } else {
      return loader(options);
    }
  };

  return canonicalUrlLoader;
}
