import { readable } from 'svelte/store';

export const isOnline = readable(true, update => {
  const on = () => {
    update(true);
  };
  const off = () => {
    update(false);
  };
  window.addEventListener('offline', off);
  window.addEventListener('online', on);

  return () => {
    window.removeEventListener('offline', off);
    window.removeEventListener('online', on);
  };
});

export const inFocus = readable(true, update => {
  const handler = () => {
    update(!document.hidden);
  };

  document.addEventListener('visibilitychange', handler);

  return () => {
    document.removeEventListener('visibilitychange', handler);
  };
});
