import { toLoggableError } from "@crown/logging";
import { stringify } from "qs";
import type { SwrResponse } from "../response-types";
import { parseResponse as defaultParseResponse } from "./response";

export const failedToFetch = "failedToFetch";

export interface Headers {
  [header: string]: string;
}

export interface FetcherOptions {
  backendUrl?: string;
  defaultHeaders?: Headers;
  fetch?: (input: RequestInfo, init: RequestInit) => Promise<Response>;
  defaultFetchOptions?: RequestInit;
  parseResponse?: (response: Response) => Promise<SwrResponse<any>>;
  /* Note that the loadingTimeout that used to be here should be replaced with a timeout-fetch */
}

export type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export interface RequestOptions {
  query?: any;
  method?: Method;
  headers?: Headers;
  backendUrl?: string;
  body?: object;
  fetchOptions?: RequestInit;
}

export type FetchResponse<T> = SwrResponse<T> & {
  ttl?: number;
};

export type FetchInProgress<T> = {
  response: Promise<FetchResponse<T>>;
  cancel: () => void;
};

export type Fetcher = (
  path: string,
  options?: RequestOptions
) => FetchInProgress<any>;

export function createFetcher(passedOptions?: FetcherOptions): Fetcher {
  let {
    backendUrl,
    defaultHeaders,
    fetch,
    defaultFetchOptions,
    parseResponse,
  } = {
    backendUrl: "",
    defaultHeaders: {},
    fetch: globalThis.fetch,
    defaultFetchOptions: {},
    parseResponse: defaultParseResponse,
    ...passedOptions,
  };

  if (!backendUrl && typeof window != "undefined") {
    backendUrl = window.location.origin;
  }

  return function request(
    path: string,
    { method = "GET", query, body, headers, fetchOptions }: RequestOptions = {}
  ) {
    if (!fetch) throw new Error("No fetch function available");

    const url = `${backendUrl}${path}${query ? stringify(query) : ""}`;

    if (!backendUrl) {
      throw new Error(`No backend URL provided; cannot use ${path} on server side`);
    }

    const req: RequestInit = {
      method,
      credentials: "same-origin",
      headers: {
        ...defaultHeaders,
        ...headers,
        ...(body ? { "Content-Type": "application/json" } : {}),
      },
      ...defaultFetchOptions,
      ...fetchOptions,
    };

    if (body) {
      req.body = JSON.stringify(body);
    }

    let cancel = () => {};
    let isCancelled = false;
    let signal: any = undefined;

    if (typeof AbortController !== "undefined") {
      const controller = new AbortController();
      signal = controller.signal;
      cancel = () => {
        controller.abort();
        isCancelled = true;
      };
    }

    const response = fetch!(url, { signal, ...req }).then(
      async (httpResponse) => {
        const ttl = getTtl(httpResponse);

        const response = await parseResponse(httpResponse);

        return { ...response, ttl } as FetchResponse<any>;
      },
      (e) => {
        if (isCancelled) {
          throw toLoggableError("Request was canceled", { code: "canceled" });
        } else {
          if (!e.message.includes(url)) {
            e.message = `${e.message} (${url})`;
          }

          e.userMessage =
            "Kunde inte ladda data. Det kan vara fel på förbindelsen.";
          e.code = failedToFetch;

          throw e;
        }
      }
    );

    return {
      cancel,
      response,
    };
  };
}

function getTtl(response: Response): number | undefined {
  const cacheControl = response.headers.get("Cache-Control");

  if (cacheControl) {
    const match = cacheControl.match(/max-age=(\d+)/);

    if (match) return parseInt(match[1]);
  }

  return undefined;
}

/** old name; remove later. */
export const createClient = createFetcher;
