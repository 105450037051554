import { info } from "@crown/logging";
import Cookies from "js-cookie";
import type { Event } from "./events";

type Handler<E extends Event> = (event: E) => void;

const handlers: Record<string, Array<Handler<any>>> = {};

export const ALL = "ALL";

/** Add handlers from specified event types.
 * The key corresponds to the `type` of the respective event.
 * The special key `ALL` (a constant defined in this file)
 * can be used to listen to all event types.
 */
export function addHandlers(newHandlers: Record<string, Handler<any>>) {
  for (let [eventType, handler] of Object.entries(newHandlers)) {
    handlers[eventType] = (handlers[eventType] || []).concat(handler);
  }
}

export function track(event: Event) {
  if (typeof window != "undefined") {
    event.cookies = Cookies.get();
    event.userAgent = navigator.userAgent;
    event.location = document.location.href;
    event.screen = {
      height: window.screen.height,
      width: window.screen.width,
    };
  }

  if (process.env.NODE_ENV == "development") {
    info(`Tracking event ${event.type}`);
  }

  for (const handler of (handlers[event.type] || []).concat(
    handlers[ALL] || []
  )) {
    handler(event);
  }
}
