import { loadResources, SWR, waitForValue } from "@crown/data";
import type { SWRStore } from "@crown/data";
import type { Crown } from "@crown/types";
import { getSWR } from "../swr";
import { get } from "svelte/store";

/** Product list is a generalization of cart and shopping list, i.e. a store containing a list of products. */
export const getProductListStore = <
  Item extends Crown.Cart.BaseItem,
  List extends Crown.Cart.Response<Item>,
  ListStore extends Crown.Cart.Store<Item, List> & {
    onPreAutoRevalidate(callback: () => void): void;
    onPostAutoRevalidate(callback: () => void): void;
  }
>(
  route: string,
  decorate: (
    cart: Crown.Cart.Store<Item, List>,
    cartStore: SWRStore<List>
  ) => ListStore,
  swr: SWR = getSWR()
): ListStore => {
  const { cart: cartStore } = loadResources(
    {
      cart: {
        path: route,
        options: {
          /* This will override the no-cache header sent from the product-list endpoint. The point is
             we don't want the browser to cache, but we also don't want it to be reloaded on every navigation. 
          */
          ttl: 120,
        },
      },
    },
    swr
  ) as {
    cart: SWRStore<List>;
  };

  // fulfilling the API here, but we're loading on get anyway
  const load = async () => waitForValue(cartStore).then(() => {});

  const add = async (item: Item | Crown.Cart.Actions.AddItem): Promise<any> => {
    // Commercetools is limited to 250 for the shopping list. Don't know about the cart.
    if ((get(cartStore).data?.items?.length || 0) > 200) {
      throw new Error("Too many items in product list.");
    }

    await cartStore.mutate({
      update: (cart) => {
        return {
          ...cart,
          items: cart.items.concat(item as Item),
        };
      },
      method: "POST",
      body: item,
    });
  };

  const remove = async (item: Crown.Cart.Actions.RemoveItem): Promise<any> => {
    await cartStore.mutate({
      update: (cart) => ({
        ...cart,
        items: cart.items.filter(({ itemId }) => itemId != item.itemId),
      }),
      method: "DELETE",
      body: item,
    });
  };

  const update = async (item: Item): Promise<any> => {
    await cartStore.mutate({
      update: (cart) => ({
        ...cart,
        items: cart.items.map((existing) =>
          item.itemId != existing.itemId ? existing : item
        ),
      }),
      method: "PUT",
      body: item,
    });
  };

  return decorate(
    {
      load,
      add,
      remove,
      update,
      subscribe: cartStore.subscribe,
    },
    cartStore
  );
};
