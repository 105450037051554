import {
  errorResponse,
  responseToError,
  successResponse,
  SwrResponse,
} from "../response-types";

/**
 * Parses a fetch response into a SuccessResponse or an ErrorResponse with the appropriate fields set
 * and with sensible error messages.
 *
 * This is only for responses from endpoints that use [server/response](../server/response.ts)
 * or a compatible response format; it should not be used for third-party APIs.
 */
export async function parseResponse(
  response: Response
): Promise<SwrResponse<any>> {
  if (response.status == 0) {
    return errorResponse({
      message: `Could not contact server`,
      userMessage:
        /** @todo: localize */ "Kan inte nå servern. Det kan vara fel på förbindelsen.",
      code: "offline",
      status: 0,
    });
  }

  const responseText = await response.text();

  const isErrorStatus = !(response.status >= 200 && response.status < 300);

  let json;

  try {
    json = JSON.parse(responseText);
  } catch (e) {
    if (isErrorStatus) {
      return errorResponse({
        message: `Got status ${
          response.status
        }, with message ${shortenServerHtmlResponse(responseText)} for ${
          response.url
        }`,
        status: response.status,
      });
    } else {
      return errorResponse({
        message: `Invalid JSON response for ${response.url}`,
      });
    }
  }

  if (isErrorStatus) {
    const { message, userMessage, code, id } = json;

    return errorResponse({
      // if the server were to send an error without a message it wouldn't get logged, so we must supply a default.
      message: message || `Got status ${response.status} for ${response.url}`,
      userMessage,
      status: response.status,
      id,
      code,
    });
  } else {
    return successResponse(json);
  }
}

export function shortenServerHtmlResponse(body: string) {
  const maxLength = 400;

  const m = body.match(/<body.*?>(.*)<\/body>/ims);

  if (m) {
    body = m[1];
  }

  return body.slice(0, maxLength) + (body.length > maxLength ? "..." : "");
}

export function assumeSuccess<T>(swrResponse: SwrResponse<T>) {
  if (swrResponse.result == "ok") {
    return swrResponse.data;
  } else if (swrResponse.result == "error") {
    throw responseToError(swrResponse);
  } else {
    throw new Error(`Got invalid response ${JSON.stringify(swrResponse)}`);
  }
}

export async function parseSuccessResponse(response: Response) {
  const swrResponse = await parseResponse(response as Response);

  return assumeSuccess(swrResponse);
}
