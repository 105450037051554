import { browser } from "$crown/env";
import { expandStoryblokImageUrl } from "./storyblok/conversion/image";

export const maxScreenWidth = 2048;

export interface Size {
  minWidth?: number;
  vw?: number;
  px?: number;
}

export interface Sizing {
  /** aspect ratio (height / width) */
  aspect?: number;

  /**
   * The size(s) at which the image will be rendered.
   * Examples:
   * [{px: 250}] => always 250px wide
   * [{px: 250}, {minWidth: 800, vw: 50}] => 250px wide on mobile, half screen width on desktop
   * [{px: 250}, {minWidth: 800, px: 500}] => 250px wide on mobile, 500px on desktop
   *
   * Must be sorted in ascending order by `minWidth`. First entry should always have `minWidth: 0`.
   */
  sizes: Size[];

  /**
   * Which image resolutions to generate. This should cover the smallest possible image up
   * until the widest image resolution multiplied by two (because of Retina screens -
   * use a factor three on mobile resolutions).
   *
   * Use the getSizing function below to generate a sensible default.
   */
  widths: number[];

  /**
   * Width to use by browsers that don't support imgset.
   */
  fallbackWidth: number;

  /* Options passed on raw to the scaler */
  rawOpts?: string;

  contain?: boolean;
  cover?: boolean;

  /* Set to false to indicate webp is either not supported
   * OR the service chooses the right format automatically */
  isWebpSupported?: boolean;

  /* Whether to first load a low resolution image to make
     image load faster.*/
  lowResFirst: boolean;
}

interface ImageScalerOpts {
  src: string;
  width: number;
  height?: number;
  cover?: boolean;
  format?: "default" | "webp" | "lowres";
  /* Options passed on raw to the scaler */
  rawOpts?: string;
}

declare const process: {
  isDevelopment: boolean;
  browser: boolean;
};

export type ImageScaler = (opts: ImageScalerOpts) => string;

// normalize and remove duplicates and keep the last occurrence of the
// parameter this way rawOpts can override the default values when needed
const removeDuplicatedSearchParams = (queryString: string): string => {
  const params = new URLSearchParams(queryString);
  const reversedEntries = [...params.entries()].reverse();
  const newParams = new URLSearchParams();

  for (const [key, value] of reversedEntries) {
    if (!newParams.has(key)) {
      newParams.set(key, value);
    }
  }
  return newParams.toString();
};

export const imgIxImageScaler: ImageScaler = ({
  src,
  width,
  height,
  format,
  rawOpts,
}) => {
  if (
    process.isDevelopment &&
    (src.includes("placeholder.com") || src == placeholderImageSrc)
  ) {
    return src;
  } else if (!src.includes("imgix") && !src.startsWith("data:")) {
    return src;
  }

  let searchParams: string = `auto=compress%2Cformat&w=${width}${
    height ? `&h=${height}&fit=crop` : ""
  }${format == "lowres" ? "&q=1&px=3&blur=60" : "&q=80"}${
    rawOpts ? "&" + rawOpts : ""
  }`;

  if (rawOpts) {
    searchParams = removeDuplicatedSearchParams(searchParams);
  }

  return src.startsWith("data:")
    ? src
    : `${src.replace(/ /g, "%20")}?${searchParams}`;
};

export const storyblokImageScaler = ({
  src,
  width,
  height,
  cover,
  format,
  rawOpts,
  crop,
}: ImageScalerOpts & {
  crop?: { top: number; left: number; bottom: number; right: number };
}) => {
  if (
    process.isDevelopment &&
    (src.includes("placeholder.com") || src == placeholderImageSrc)
  ) {
    return src;
  } else if (src.startsWith("/")) {
    src = expandStoryblokImageUrl(src);
  } else if (!src.includes("storyblok") && !src.startsWith("data:")) {
    return src;
  }

  return src.startsWith("https://")
    ? `${src.replace(/ /g, "%20")}/m/${cover ? "" : "fit-in/"}${
        crop
          ? `/${crop.left}x${crop.top}:${crop.right}x${crop.bottom}`
          : `${width}x${height ?? 0}`
      }/${
        format == "lowres"
          ? "filters:blur(40):quality(10)"
          : "filters:quality(75)"
      }${rawOpts || ""}`
    : src;
};

export const base64ImageScaler: ImageScaler = ({ src }) =>
  // modify the src to indicate we support base64 images
  src.startsWith("data:") ? src + " " : src;

/* Useful for seeing what resolutions are actually used.
     Also used as fallback when images are missing. */
export const placeholderImageScaler = ({
  width,
  height,
}: {
  width: number;
  height?: number;
}) => `https://via.placeholder.com/${width}x${height || width}`;

/* Local image that can be used as fallback when images are missing */
export const placeholderImageSrc = "/images/placeholder.png";

export const defaultSizing = {
  /* Both imgix and storyblok support automatic format negotiation,
   * so we don't need to explicitly include a webp version */
  isWebpSupported: false,
};

export const defaultStoryblokSizing = {
  ...defaultSizing,
};

export const defaultImgixSizing = {
  ...defaultSizing,
};

export const pufferTallSizing = {
  ...defaultSizing,
  aspect: 4 / 3,
};

export const pufferWideSizing = {
  ...defaultSizing,
  aspect: 6 / 10,
};

export const categoryIconSizing = {
  ...pufferWideSizing,
  contain: true,
  sizes: [{ px: 90 }],
  rawOpts: ":fill(transparent)",
};

/**
 * We have four types of product images:
 * - Product images. These are in a 4:5 aspect ratio
 * - Detail images: These are in a 4:5 aspect ratio but have whitespace at the top and bottom.
 * - Model images: These are in a 399:357 aspect ratio.
 * - List images: Theses are in a 30/37 aspect ratio, used in lists such as navigation and filter
 * We should:
 * - Add padding to the product images to give them a 399:357 aspect ratio.
 *   We should also add some whitespace on the sides (see CSS variable --product-image-padding)
 * - Crop the detail image whitespace and fit it inside the image. This makes sure the whole image is visible and not being cropped out as the detail image format that we want is in landscape.
 * - Render model images cropped by just a tiny amount to 8:9.
 */

export const defaultProductSizing = {
  ...defaultImgixSizing,
  aspect: 4 / 5,
};

export const defaultModelSizing = {
  ...defaultProductSizing,
  aspect: 9 / 8,
};

export const defaultDetailSizing = {
  ...defaultModelSizing,
  contain: true,
  rawOpts: `fit=clip&crop=left,top&rect=.006,.022,.988,.925`,
};

export const defaultListSizing = {
  ...defaultProductSizing,
  contain: true,
  aspect: 30 / 37,
  sizes: [{ px: 37 }],
};

/* This started out as a list of the most common resolutions, but have been rounded up to
    be largely even multiples of each other so that when we generate pixel doubling resolutions
    the number of widths doesn't explode */
const defaultScreenWidths = [384, 428, 512, 768, 1024, 1536, maxScreenWidth];

/**
 * Calculates a sensible set of widths given all other sizing options. It does this by starting from a set
 * of common screen sizes, calculating what the image size would be for each resolution and adding retina
 * version of each.
 */
export function getSizing(
  opts: Omit<Sizing, "widths" | "fallbackWidth" | "lowResFirst"> & {
    /**
     * Optionally describe the screen width range at which this image might be used.
     * E.g. if this image is only rendered at mobile resolutions, send `[0, 768]`
     */
    screenSizeRange?: [number, number];

    lowResFirst?: boolean;
  }
): Sizing {
  const { screenSizeRange, sizes } = opts;

  let screenWidths = defaultScreenWidths;

  if (screenSizeRange) {
    const [min, max] = screenSizeRange;

    screenWidths = screenWidths.filter((width) => width >= min && width <= max);
  }

  let atSize = 0;
  let widths: number[] = [];

  function found(width: number) {
    if (!widths.includes(width)) {
      widths.push(width);
    }
  }

  screenWidths.forEach((screenWidth) => {
    if (sizes[atSize + 1] && (sizes[atSize + 1].minWidth || 0) < screenWidth) {
      atSize++;
    }

    const size = sizes[atSize];
    const width =
      Math.round(((size.vw || 0) * screenWidth) / 100) || sizes[atSize].px || 0;

    found(width);
    found(width * 2);

    if (width <= 414) {
      // various iPhones have a pixel ratio of 3
      found(width * 3);
    }
  });

  widths.sort((a, b) => a - b);

  if (widths.length > 10) {
    let i = widths.length - 1;

    while (i > 0) {
      if (widths[i] < widths[i - 1] * 1.3) {
        widths.splice(i - 1, 1);
      }

      i--;
    }
  }

  let medianSize: Size;
  let medianScreenSize = 0;

  if (screenSizeRange) {
    medianScreenSize = Math.round(
      (screenSizeRange[0] + screenSizeRange[1]) / 2
    );

    medianSize =
      sizes.find(
        (size) => size.minWidth && size.minWidth >= medianScreenSize
      ) || sizes[sizes.length - 1];
  } else {
    medianSize = sizes[Math.floor(sizes.length / 2)];
  }

  const fallbackWidth =
    medianSize.px ||
    Math.round(
      ((medianSize.vw || 0) * (medianScreenSize || medianSize.minWidth || 0)) /
        100
    );

  if (browser && fallbackWidth == 0) {
    throw new Error(`Got fallback width 0 for sizing ${opts}`);
  }

  const lowResFirst =
    opts.lowResFirst != null
      ? opts.lowResFirst
      : fallbackWidth * fallbackWidth * (opts.aspect || 1) > 250 * 250;

  return { ...opts, widths, fallbackWidth, lowResFirst };
}
