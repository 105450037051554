import morphdom from "morphdom";
import { attach, detach, update } from "./hydrate";
import { warn } from "@crown/logging";
const isSafari =
  typeof navigator !== "undefined" &&
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function transformDocument(
  target: Document,
  svelteContext: Map<string, any>
) {
  morphdom(document.documentElement, target.documentElement, {
    onNodeAdded(node) {
      if (node.nodeName === "CROWN-COMPONENT") {
        if (
          (node as HTMLElement).dataset &&
          (node as HTMLElement).dataset.mode === "mount"
        ) {
          attach({
            element: node as HTMLElement,
            hydration: false,
            mode: "mount",
            svelteContext,
          });
        }
      }

      // XXX: Safari freaks out when responsive images are added, this fixes it.
      // I have no idea why.
      if (isSafari && node.nodeName === "PICTURE") {
        for (let n of node.childNodes) {
          node.appendChild(n);
        }
      }
      return node;
    },
    onBeforeElUpdated(fromEl, toEl) {
      if (
        fromEl.nodeName === "CROWN-COMPONENT" &&
        toEl.nodeName === "CROWN-COMPONENT"
      ) {
        if (
          fromEl.getAttribute("data-component") ===
          toEl.getAttribute("data-component")
        ) {
          update(fromEl, toEl);
        } else {
          warn(`had to reattach data-component node`)

          fromEl.parentNode?.replaceChild(toEl, fromEl);
          detach(fromEl);
          attach({
            element: toEl,
            hydration: false,
            mode: "mount",
            svelteContext,
          });
        }
        // Prevent morphdom from touching the component
        return false;
      }

      // Do not un-load lazy loaded fonts
      if (
        toEl.nodeName === "LINK" &&
        toEl.hasAttribute("onload") &&
        toEl.hasAttribute("media")
      )
        toEl.removeAttribute("media");

      if (
        (fromEl as HTMLElement).dataset &&
        typeof (fromEl as HTMLElement).dataset.crownIgnore !== "undefined"
      ) {
        // reverse for style and link elements
        if (fromEl.nodeName === "STYLE" || fromEl.nodeName === "LINK") {
          return true;
        }
        return false;
      }

      return true;
    },
    onBeforeElChildrenUpdated(fromEl, toEl) {
      // Prevent morphdom from touching the component children
      if (
        fromEl.nodeName === "CROWN-COMPONENT" &&
        toEl.nodeName === "CROWN-COMPONENT"
      ) {
        return false;
      }

      if (
        (fromEl as HTMLElement).dataset &&
        typeof (fromEl as HTMLElement).dataset.crownIgnore !== "undefined"
      ) {
        // reverse for style and link elements
        if (fromEl.nodeName === "STYLE" || fromEl.nodeName === "LINK") {
          return true;
        }
        return false;
      }

      return true;
    },
    onBeforeNodeDiscarded(node) {
      if (node.nodeName === "CROWN-COMPONENT") {
        detach(node as HTMLElement);
        return false;
      }

      if (node.nodeName === "STYLE" || node.nodeName === "LINK") {
        // reverse logic to discard crownIgnored style and link elements as they are kept by default
        if (typeof (node as HTMLElement).dataset.crownIgnore !== "undefined") {
          return true;
        }
        // keep style tags in order to not break styling when running transitions
        return false;
      }

      if (
        (node as HTMLElement).dataset &&
        typeof (node as HTMLElement).dataset.crownIgnore !== "undefined"
      ) {
        return false;
      }

      return true;
    },
  });
}
