import { waitForValue } from "@crown/data";
import { debug } from "@crown/logging";
import type { LocaleConfig } from "../locale";
import type { Storefront } from "../types/Storefront";
import { clearUtm, getUtm } from "./utm";

/**
 * Store metadata on the cart that is only available on the client.
 * This is called on GTM initialization but also when a cart is created.
 */
export async function setCartClientMetadata(
  cartStore: Storefront.CartStore,
  locale: LocaleConfig
) {
  const deviceType =
    typeof window != "undefined" && window.innerWidth > 768
      ? "DESKTOP"
      : "MOBILE";

  const data = await waitForValue(cartStore);

  // if length is zero then there isn't actually a cart
  if (data.items.length > 0) {
    if (process.isDevelopment) {
      debug(`Storing cart client metadata...`);
    }

    await cartStore.setClientMetadata({
      deviceType,
      utm: getUtm() || undefined,
    });

    clearUtm();
  }
}
