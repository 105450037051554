import { toLoggableError } from "@crown/logging";
import type { Request } from "./transitional-request";

export const normalizePath = (...paths: string[]) => {
  const path = paths.join("/");
  let normalizedPath = path.startsWith("/") ? path : `/${path}`;
  normalizedPath = normalizedPath.replace(/\/+/g, "/");
  return normalizedPath;
};

export function getRequestUrl(request: Request) {
  return new URL(
    `${request.protocol}://${request.get("host")}${request.baseUrl || ""}`
  );
}

/* Creates a link to a page on the current site. Handles site domains
 * that have the locale as first path segment.
 */
export function buildUrlFromRequest(
  domain: string,
  ...segments: string[]
): string {
  return buildUrl(domain, ...segments);
}

export function buildUrl(base: string, ...segments: string[]): string {
  const path = segments
    // Remove empty/nullish segments
    .filter((segment) => !!segment)
    // Replace leading and trailing slashes with a single leading slash
    .map((segment, i) => segment.replace(/^\.?\/*(.*)\/*$/, "/$1"))
    // Join remaining segments
    .join("")
    // remove slash before parameters or hash
    .replace(/\/(\?|&|#[^!])/g, "$1");

  try {
    return new URL(path, base).href;
  } catch (e: any) {
    throw toLoggableError(e, {
      context: {
        path,
        base,
      },
    });
  }
}
